import { VantInstall } from '@/plugins/vant'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import init from '@/plugins/init'
import { CustomError } from '@/utils/error'

const pinia = createPinia()
const app = createApp(App)

VantInstall(app)
init()

app.config.errorHandler = (err, vm, info) => {
  if (err instanceof CustomError) {
    console.debug(err.message)
  } else {
    console.error(err)
  }
}

app.use(pinia)
app.use(store).use(router).mount('#app')









