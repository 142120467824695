import { Notify } from 'vant'
import type { NotifyOptions } from 'vant'
import type { ComponentPublicInstance } from 'vue'
type NotifyMessage = string | number

type NotifyMethod = 'success' | 'warning' | 'error'

type ExtendMethods = {
  [K in NotifyMethod]?: (
    message: NotifyMessage,
    options?: NotifyOptions
  ) => ComponentPublicInstance | undefined
}

type NotifyUtil = typeof Notify & ExtendMethods

const notify: NotifyUtil = Notify

type ValueMapTuple = [NotifyMethod, string]

const NotifyMethodValueMap: ValueMapTuple[] = [
  ['success', 'success'],
  ['warning', 'warning'],
  ['error', 'danger']
]
NotifyMethodValueMap.forEach(([key, type]) => {
  notify[key] = function (message: NotifyMessage, options: NotifyOptions = {}) {
    return this(Object.assign({ type, message }, options))
  }
})

export default notify as typeof Notify & Required<ExtendMethods>
