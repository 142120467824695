import axios from 'axios'
import {stringify} from 'qs'
import store from '@/store'
import notify from '@/utils/notify'
import dialog from '@/utils/dialog'
import router from '@/router'
import {confirmLogined} from '@/utils/auth'
import contentDispositionParser from './content-disposition-parser'
import {saveAs} from 'file-saver'
// import type { AxiosRequestConfig } from 'axios'
// import { useAxios } from '@vueuse/integrations/useAxios'

export const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/v1/'
})
const tokenKeys = ['token', 'check-wechat-token', 'check-qq-token']

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    if (!config.headers) {
      config.headers = {}
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    // 尝试释放相关参数
    if (store.state.user && store.state.user.wechat && store.state['check-wechat-token']) {
      store.commit('setCheckWechatToken', '')
    }
    if (store.state.user && store.state.user.qq && store.state['check-qq-token']) {
      store.commit('setCheckQQToken', '')
    }

    tokenKeys.forEach((k) => {
      const t = (store.state as Record<string, any>)[k]
      if (t) {
        ;(config.headers as Record<string, any>)[k] = t
      }
    })

    if (config.data) {
      config.data = stringify(config.data)
    }

    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.headers['content-disposition']) {
      const metaData = contentDispositionParser(
        response.headers['content-disposition']
      )
      if (metaData && metaData.type === 'attachment') {
        saveAs(response.data, metaData.filename as string)
        return response
      }
    }
    response.headers.anycodes_request_id = response.data.request_id
    response.data = response.data.body


    return response
  },
  async function (error) {
    switch (error.response.status) {
      /* 特殊处理401，但是返回的的数据里面有check-wechat-token的情况 */
      case 401: {
        if (error.response.data.body['check-wechat-token']) {
          store.commit('setCheckWechatToken', error.response.data.body['check-wechat-token']);
        } else {
          store.dispatch('clearLoginData')
          await confirmLogined()
        }
        break
      }
      case 400:
      case 404:
      case 403: {
        if (error.response.data.body) {
          notify.error(error.response.data.body)
        }
        break
      }
      case 500: {
        // 做一些特殊操作
        if (error.response.data.body) {
          notify.error(error.response.data.body)
        }
        break
      }
      default:
        break
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    throw error
  }
)

export default instance

// export const useRequest = (url: string, config: AxiosRequestConfig = {}) => {
//   return useAxios(url, config, instance, {
//     immediate: false
//   })
// }
