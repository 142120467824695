import { getRuntimes } from '@/apis/complier'
import store from '@/store'

import VConsole from 'vconsole'

export default function init() {
  getRuntimes().then((res) => {
    store.commit('editor/setRuntimes', res.data)
  })
  if (store.getters.vconsole) {
    const vConsole = new VConsole({ theme: 'dark' })
  }

  window.addEventListener('resize', function () {
    if (document.activeElement) {
      if (
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA'
      ) {
        window.setTimeout(function () {
          if ('scrollIntoView' in document.activeElement!) {
            document.activeElement.scrollIntoView(false)
          } else {
            // @ts-ignore
            document.activeElement.scrollIntoViewIfNeeded(false)
          }
        }, 0)
      }
    }
  })
}
