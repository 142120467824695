import request from '../request'
import type { AxiosPromise } from 'axios'
import type { IRegisterResponse } from '@/apis/user/account'
/**
 * 获取QQ登陆地址
 * @returns
 */
export function getQQLink(redirect_url?: string): AxiosPromise<{ url: string }> {
  return request('user/qq/link', {
    method: 'GET',
    params: {
      client: 'mobile',
      redirect_url
    }
  })
}

/**
 * 绑定QQ
 * @param data
 * @returns
 */
export function bindQQ(data: { code: string; state: string }): AxiosPromise<
  {
    'check-qq-token'?: string
  } & Partial<IRegisterResponse>
> {
  return request('user/qq', {
    method: 'POST',
    params: {
      client: 'mobile'
    },
    data
  })
}
/**
 * 解绑QQ
 * @returns
 */
export function unbindQQ() {
  return request('user/qq', {
    method: 'DELETE'
  })
}
