import type { Module } from 'vuex'
import type { RootState } from './index'

export interface WebsocketState {
  socket: WebSocket | null
}
const websocket: Module<WebsocketState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      socket: null
    }
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
}

export default websocket
