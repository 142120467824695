
import { RouteRecordRaw,useRouter } from 'vue-router'
import { SettingChildRouteEnum } from '@/enum/editor'
import store from '@/store'

/* 根据屏幕宽高判断显示mobile组件还是pad组件 */
const getWindowjudge = () =>{
  const judgetablet = document.documentElement.clientHeight<document.documentElement.clientWidth
    store.commit('setisTablet', judgetablet)
}

getWindowjudge()



const istablet = store.getters.istablet

const route: RouteRecordRaw = {
  path: '',
  name: 'Editor',
  alias: '/editor',
  component: () =>istablet?import('@/views/TabView/EditorTablet.vue') :import('@/views/TabView/Editor.vue'),
  children: [
    {
      path: '/editor/ocr',
      name: 'EditorOcr',
      component: () => import('@/views/Popup/Editor/CodeReg.vue')
    },
    {
      path: '/editor/share',
      name: 'EditorShare',
      component: () => import('@/views/Popup/Editor/ShareCode.vue')
    },
    {
      path: '/editor/showstack',
      name: 'EditorShowStack',
      component: () => import('@/views/Popup/Editor/ShowStack.vue')
    },
    {
      path: '/editor/create',
      name: 'EditorFileCreator',
      component: () => import('@/views/Popup/Editor/FileCreator.vue')
    },
    {
      path: '/editor/share-records',
      name: 'EditorShareRecords',
      component: () => import('@/views/Popup/Editor/ShareRecords.vue')
    },
    {
      path: '/editor/fs',
      name: 'EditorFileManager',
      component: () => import('@/views/Popup/SubLayout.vue'),
      children: [
        {
          path: '/editor/fs',
          name: 'EditorFileManagerIndex',
          component: () => import('@/views/Popup/Editor/FileManager.vue')
        },
        {
          path: '/editor/fs/edit',
          name: 'EditorFileManagerEdit',
          component: () =>
            import('@/views/Popup/Editor/FileManager/EditFile.vue')
        }
      ]
    },
    {
      path: '/editor/setting',
      name: 'EditorSetting',
      component: () => import('@/views/Popup/SubLayout.vue'),
      children: [
        {
          path: '/editor/setting',
          name: 'EditorSettingIndex',
          component: () => import('@/views/Popup/Editor/Setting.vue')
        },
        {
          path: '/editor/setting/' + SettingChildRouteEnum.FontSize,
          name: 'EditorSettingFontSize',
          component: () => import('@/views/Popup/Editor/Setting/FontSize.vue')
        },
        {
          path: '/editor/setting/' + SettingChildRouteEnum.ResultHieght,
          name: 'EditorSettingResultHieght',
          component: () =>
            import('@/views/Popup/Editor/Setting/ResultHieght.vue')
        },
        {
          path: '/editor/setting/' + SettingChildRouteEnum.Theme,
          name: 'EditorSettingTheme',
          component: () => import('@/views/Popup/Editor/Setting/Theme.vue')
        },
        {
          path: '/editor/setting/' + SettingChildRouteEnum.RunScript,
          name: 'EditorSettingRunScript',
          component: () => import('@/views/Popup/Editor/Setting/RunScript.vue')
        }
      ]
    }
  ]
}

export default route
