import type { Module } from 'vuex'
import type { RootState } from './index'
import { ModeList, ThemeList } from '@icebreakers/ace-editor'
import type { ComponentPublicInstance } from 'vue'
import { getIdeConfig } from '@/apis/complier'
// import lowerCase from 'lodash/lowerCase'

export interface EditorState {
  runtimes: any[]
  mode: string
  theme: string
  resultHeight: number
  ModeList: string[]
  ThemeList: string[]
  instance: ComponentPublicInstance | null
  options: Record<string, any>
}
const editor: Module<EditorState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      instance: null,
      runtimes: [],
      mode: 'python',
      theme: 'lesser-dark',
      resultHeight: 0.5,
      ModeList,
      ThemeList:[
        '3024-day',
        '3024-night',
        'abcdef',
        'ambiance-mobile',
        'ambiance',
        'ayu-dark',
        'ayu-mirage',
        'base16-dark',
        'base16-light',
        'bespin',
        'blackboard',
        'cobalt',
        'colorforth',
        'darcula',
        'duotone-dark',
        'duotone-light',
        'eclipse',
        'elegant',
        'erlang-dark',
        'gruvbox-dark',
        'hopscotch',
        'icecoder',
        'idea',
        'isotope',
        'lesser-dark',
        'liquibyte',
        'lucario',
        'material-darker',
        'material-ocean',
        'material-palenight',
        'material',
        'mbo',
        'mdn-like',
        'midnight',
        'monokai',
        'moxer',
        'neat',
        'neo',
        'night',
        'nord',
        'oceanic-next',
        'panda-syntax',
        'paraiso-dark',
        'paraiso-light',
        'pastel-on-dark',
        'rubyblue',
        'seti',
        'shadowfox',
        'solarized',
        'ssms',
        'the-matrix',
        'tomorrow-night-bright',
        'tomorrow-night-eighties',
        'ttcn',
        'twilight',
        'vibrant-ink',
        'xq-dark',
        'xq-light',
        'yeti',
        'yonce'
      ],
      options: {
        // 联想输入,自动完成
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        fontSize: 16,
        enableCodeFolding:false,
        // 代码行号'
        showLineNumbers: true,
        // 侧栏状态
        // 活动行高亮
        highlightActiveLine: true,
        highlightGutterLine: true,
        resultHeight: 0.5,
        showGutter: true,
        autoIndent: true,
        wrap: false,
        // displayIndentGuides: false
      }
    }
  },
  getters: {
    mode: (s) => s.mode,
    theme: (s) => s.theme,
    resultHeight: (s) => s.resultHeight,
    langList: (s) => Object.keys(s.runtimes),
    runtimes: (s) => s.runtimes,
    runtimesMap: (s, getters) => {
      const runtimes = Object.values(
        getters.runtimes as Record<
          string,
          { id: number; language: string; version: string }[]
        >
      )
        .reduce((acc, cur) => {
          acc = acc.concat(cur)
          return acc
        }, [])
        .reduce<Record<number, { language: string; version: string }>>(
          (acc, cur) => {
            acc[cur.id] = {
              language: cur.language,
              version: cur.version
            }
            return acc
          },
          {}
        )
      return runtimes
    },
    options: (s) => s.options,
    ThemeList: (s) => s.ThemeList,
    ModeList: (s) => s.ModeList
  },
  mutations: {
    setOptions(state, payload) {
      if (typeof payload === 'object') {
        Object.entries(payload).forEach(([k, v]) => {
          state.options[k] = v
        })
      }
    },
    setRuntimes: (state, payload) => {
      state.runtimes = payload
    },
    setMode: (state, payload) => {
      state.mode = payload
    },
    setTheme: (state, payload) => {
      state.theme = payload
    },
    setResultHeight: (state, payload) => {
      state.resultHeight = payload
    },
    setInstance: (s, payload) => {
      s.instance = payload
    }
  },
  actions: {
    setConfig({ commit }, data) {
      commit('setOptions', {
        fontSize: data.font_size,
        // theme: data.theme_style,
        showLineNumbers: data.line_number,
        enableBasicAutocompletion: data.automatic_completion,
        enableLiveAutocompletion: data.automatic_completion,
        highlightActiveLine: data.highlight_active_line,
        // resultHeight: data.result_height,
        // wrap: false,
        showGutter: data.show_gutter,
        autoIndent: data.auto_indent
        // 侧边栏
        // showGutter: false
      })
      commit('setResultHeight', data.result_height)
      commit('setTheme', data.theme_style)
    },

    async getConfig({ rootGetters, dispatch }) {
      if (rootGetters.isLogined) {
        const { data } = await getIdeConfig()
        dispatch('setConfig', data)
        // commit('setOptions', {
        //   fontSize: data.font_size,
        //   // theme: data.theme_style,
        //   showLineNumbers: data.line_number,
        //   enableBasicAutocompletion: data.automatic_completion,
        //   enableLiveAutocompletion: data.automatic_completion,
        //   highlightActiveLine: data.highlight_active_line,
        //   // resultHeight: data.result_height,
        //   // wrap: false,
        //   showGutter: data.show_gutter,
        //   autoIndent: data.auto_indent
        //   // 侧边栏
        //   // showGutter: false
        // })
        // commit('setResultHeight', data.result_height)
        // commit('setTheme', data.theme_style)
      }
    },
    setLanguage({ commit }, payload: string) {
      // ModeList.includes(payload) && commit('setMode', payload)
      commit('setMode', payload)
    }
  },
  modules: {}
}

export default editor
