export type SettingChildRoute =
  | 'FontSize'
  | 'ResultHieght'
  | 'Theme'
  | 'RunScript'

export const SettingChildRouteEnum: Record<
  SettingChildRoute,
  Lowercase<SettingChildRoute>
> = {
  FontSize: 'fontsize',
  ResultHieght: 'resulthieght',
  Theme: 'theme',
  RunScript: 'runscript'
} as const
