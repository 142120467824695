import { createStore } from 'vuex'
import createPersistedState from './plugins/persistedstate'
import type { IUser } from '@/apis/user'
import { getUser } from '@/apis/user'
import editor from './editor'
import terminal from './terminal'
import library from './library'
import { getFileList } from '@/apis/complier'

export interface RootState {
  token: string
  quick: string
  'check-wechat-token': string
  'check-qq-token': string
  user?: IUser
  files: any[]
  wechatcode: string
  hideTabbar: boolean
  share: string
  vconsole: boolean
  istablet:boolean,
  avatar:string,
  username:string
}

const store = createStore<RootState>({
  state: {
    token: '',
    quick: '',
    'check-qq-token': '',
    'check-wechat-token': '',
    user: undefined,
    files: [],
    wechatcode: '',
    hideTabbar: false,
    share: '',
    vconsole: false,
    istablet:false,
    avatar:'',
    username:''
  },

  getters: {
    token: (s) => s.token,
    user: (s) => s.user,
    quick: (s) => s.quick,
    files: (s) => s.files,
    isLogined: (s) => Boolean(s.token),
    wechatcode: (s) => s.wechatcode,
    share: (s) => s.share,
    vconsole: (s) => s.vconsole,
    istablet:(s)=>s.istablet,
    avatar:(s)=>s.avatar,
    username:(s)=>s.username,
    isWeappWrapped: (s) => Boolean(s.wechatcode),
    hideTabbar: (s) => s.hideTabbar
  },
  mutations: {
    setToken: (s, payload) => {
      s.token = payload
    },
    setUser: (s, payload) => {
      s.user = payload
    },
    setFiles: (s, payload) => {
      s.files = payload
    },
    setQuick: (s, payload) => {
      s.quick = payload
    },
    setCheckQQToken: (s, payload) => {
      s['check-qq-token'] = payload
    },
    setCheckWechatToken: (s, payload) => {
      s['check-wechat-token'] = payload
    },
    setWeChatCode: (s, payload) => {
      s.wechatcode = payload
    },
    setShareCode: (s, payload) => {
      s.share = payload
    },
    setvConsole: (s, payload) => {
      s.vconsole = payload
    },
    setisTablet: (s, payload) => {
      s.istablet = payload
    },
    setavatar: (s, payload) => {
      s.avatar = payload
    },
    setusername: (s, payload) => {
      s.username = payload
    }
  },
  actions: {
    setHideTabbar({ state }, payload: boolean) {
      state.hideTabbar = payload
    },
    clearLoginData({ dispatch }) {
      dispatch('setToken', '')
      dispatch('setUser', undefined)
    },
    setToken: ({ commit }, payload) => {
      commit('setToken', payload)
    },
    setUser: ({ commit }, payload) => {
      commit('setUser', payload)
    },
    async getUser({ commit }) {
      const res = await getUser()
      commit('setUser', res.data)
    },
    async getFileList({ commit, getters }) {
      if (getters.isLogined) {
        const res = await getFileList()
        commit('setFiles', res.data)
      }
      return getters.files
    }
  },
  modules: {
    editor,
    terminal,
    library
  },
  plugins: [
    createPersistedState({
      key: 'anycodes-login-cache',
      paths: [
        'token',
        'quick',
        // 'check-qq-token',
        // 'check-wechat-token',
        'user',
        'vconsole',
        'istablet',
        'avatar',
        'username',
        'editor.runtimes',
        'editor.mode',
        'editor.theme',
        'editor.resultHeight',
        'editor.options'
      ]
    })
  ]
})

export default store
