import type { App } from 'vue'
import { vLoading } from 'element-plus/es/components/loading/src/directive'
import {
  Form,
  Field,
  CellGroup,
  ConfigProvider,
  Button,
  Divider,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  CountDown,
  Tabbar,
  TabbarItem,
  Toast,
  Popover,
  Popup,
  Icon,
  Uploader,
  Image as VanImage,
  Tab,
  Tabs,
  Cell,
  Dialog,
  Picker,
  Switch,
  Slider,
  Search,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  ActionSheet,
  Loading,
  DropdownMenu,
  DropdownItem,
  Skeleton,
  Sidebar,
  SidebarItem
} from 'vant'
// 这样写是为了方便 CV
export const VantInstall = (app: App<Element>) => {
  app.use(ConfigProvider)
  app.use(Form)
  app.use(Field)
  app.use(CellGroup)
  app.use(Button)
  app.use(Divider)
  app.use(Radio)
  app.use(RadioGroup)
  app.use(Checkbox)
  app.use(CheckboxGroup)
  app.use(CountDown)
  app.use(Tabbar)
  app.use(TabbarItem)
  app.use(Toast)
  app.use(Popover)
  app.use(Popup)
  app.use(Icon)
  app.use(Uploader)
  app.use(VanImage)
  app.use(Tab)
  app.use(Tabs)
  app.use(Cell)
  app.use(Dialog)
  app.use(Picker)
  app.use(Switch)
  app.use(Slider)
  app.use(Search)
  app.use(Swipe)
  app.use(SwipeItem)
  app.use(Collapse)
  app.use(CollapseItem)
  app.directive('loading', vLoading)
  app.use(Step)
  app.use(Steps)
  app.use(ActionSheet)
  app.use(Loading)
  app.use(DropdownMenu)
  app.use(DropdownItem)
  app.use(Skeleton)
  app.use(Sidebar)
  app.use(SidebarItem)
}
