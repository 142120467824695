import type { Module } from 'vuex'
import type { RootState } from './index'
export interface LibraryState {
  truemodulename: '', /* 每个真题模块的名称 */
  examid: 0
}
const library: Module<LibraryState, RootState> = {
  namespaced: true,
  state: () => {
    return {
      truemodulename: '',
      examid: 0
    }
  },
  getters: {
    truemodulename: (s) => s.truemodulename,
    examid: (s) => s.examid
  },
  mutations: {
    setTruemodulename: (state, payload) => {
      state.truemodulename = payload
    },
    setexamid: (state, payload) => {
      state.examid = payload
    }
  },
  actions: {},
  modules: {}
}

export default library
