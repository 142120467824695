import dialog from '@/utils/dialog'
import router from '@/router'
export async function confirmLogined() {
  try {
    await dialog.confirm({
      title: '您还没有登录',
      message: '点击确定跳转到登录页面',
      confirmButtonColor: '#386DFF',
      cancelButtonColor: '#386DFF'
    })
    router.push('/login')
  } catch (error) {
    // do cancel
    console.log('auth error',error)
  }
}
