import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import editorRoute from './editor'
import store from '@/store'
/* 根据屏幕宽高判断显示mobile组件还是pad组件 */
const getWindowjudge = () =>{
  const judgetablet = document.documentElement.clientHeight<document.documentElement.clientWidth
    store.commit('setisTablet', judgetablet)
}

// getWindowjudge()

/* 监听屏幕尺寸变化 */
// window.addEventListener('resize',debounce(getWindowjudge,500))
/* 监听屏幕旋转 */
window.addEventListener('orientationchange',function(){
  setTimeout(()=>{
    getWindowjudge()
    window.location.reload()
  },500)
    
})
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'EditorIndex',
    component: () =>store.getters.istablet?import('@/views/indextablet.vue'): import('@/views/index.vue'),
    children: [
      editorRoute,
      {
        path: '/resource/:type',
        name: 'Resource',
        component: () => store.getters.istablet?import('@/views/TabView/ResourceTablet.vue'):import('@/views/TabView/Resource.vue'),
        children: [
          {
            path: '/resource/info/:searchvalue/:articleid?',
            name: 'ResourceSearch',
            component: () => store.getters.istablet?import('@/views/Popup/Resource/SearchListTablet.vue'):import('@/views/Popup/Resource/SearchList.vue'),
          },
          {
            path: '/resource/article/:articleid',
            name: 'ResourceArticle',
            component: () => import('@/views/Popup/Resource/Article.vue')
          },
          {
            path: '/resource/articlelist/:id/:name/:articleid?',
            name: 'ResourceArticleList',
            meta:{
              keepAlive:true
            },
            component: () =>  store.getters.istablet?import('@/views/Popup/Resource/ArticleListTablet.vue'):import('@/views/Popup/Resource/ArticleList.vue')
          },
          {
            path: '/resource/books/:detailid',
            name: 'ResourceBookDetail',
            component: () => import('@/views/Popup/Resource/BookDetail.vue')
          }
        ]
      },
      {
        path: 'library',
        name: 'Library',
        component: () => store.getters.istablet?import('@/views/TabView/LibraryTablet.vue'):import('@/views/TabView/Library.vue'),
        children: [
          {
            path: '/library/special',
            name: 'LibrarySpecial',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibrarySpecialTablet.vue'):import('@/views/Popup/Library/LibrarySpecial.vue')
          },
          {
            path: '/library/special/:paperid',
            name: 'LibrarySpecialExam',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryPaperContentTablet.vue'):import('@/views/Popup/Library/LibraryPaperContent.vue')
          },
          {
            path: '/library/specialwrited',
            name: 'LibrarySpecialWrited',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibrarySpecialWritedTablet.vue'):import('@/views/Popup/Library/LibrarySpecialWrited.vue')
          },
          {
            path: '/library/specialwrited/:paperid/:questionid?',
            name: 'LibrarySpecialWritedDetail',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryPaperContentTablet.vue'):import('@/views/Popup/Library/LibraryPaperContent.vue')
          },
          {
            path: '/library/truewrited',
            name: 'LibraryTrueWrited',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibrarySpecialWritedTablet.vue'):import('@/views/Popup/Library/LibrarySpecialWrited.vue')
          },
          {
            path: '/library/truewrited/:paperid',
            name: 'LibraryTrueWritedDetail',
            component: () => import('@/views/Popup/Library/LibraryPaperContent.vue')
          },
          {
            path: '/library/true',
            name: 'LibraryTrue',
            component: () => import('@/views/Popup/SubLayout.vue'),
            children: [
              {
                path: '/library/true/:type/:id?',
                name: 'LibraryTrueIndex',
                // meta:{
                //   keepAlive:true
                // },

                component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryTrueTablet.vue'):import('@/views/Popup/Library/LibraryTrue.vue')
              },
              {
                path: '/library/true/paperlist/:type/:id',
                name: 'LibraryPaperList',
                component: () => import('@/views/Popup/Library/LibraryPaperList.vue')
              },
              {
                path: '/library/true/paperlist/:paperid',
                name: 'LibraryPaperContent',
                component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryPaperContentTablet.vue'):import('@/views/Popup/Library/LibraryPaperContent.vue')
              },
              {
                path: '/library/true/paperlist/result/:paperid/:questionid?',
                name: 'LibraryPaperResult',
                component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryPaperContentTablet.vue'):import('@/views/Popup/Library/LibraryPaperContent.vue')
              },
              {
                path: '/library/true/comment/:questionid',
                name: 'LibraryPaperComment',
                component: () => import('@/views/Popup/Library/LibraryComment.vue')
              },
              {
                path: '/library/true/addcomment/:questionid',
                name: 'LibraryPaperAddComment',
                component: () => import('@/views/Popup/Library/LibraryAddComment.vue')
              }
            ]
          },
          {
            path: '/library/quizlist',
            name: 'LibraryQuizlist',
            component: () => import('@/views/Popup/SubLayout.vue'),
            children: [
              {
                path: '/library/quizlist/:type/:id?',
                name: 'LibraryQuizlistIndex',
                component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryTrueTablet.vue'):import('@/views/Popup/Library/LibraryTrue.vue')
              }
            ]
          },
          {
            path: '/library/random',
            name: 'LibraryRandom',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryPaperContentTablet.vue'):import('@/views/Popup/Library/LibraryPaperContent.vue')
          },
          {
            path: '/library/history/comment',
            name: 'LibraryHistoryComment',
            component: () => store.getters.istablet?import('@/views/Popup/Library/LibraryHistoryCommentTablet.vue'):import('@/views/Popup/Library/LibraryHistoryComment.vue')
          },
          {
            path: '/library/history/comment/:questionid',
            name: 'LibraryHistoryCommentQuestion',
            component: () => import('@/views/Popup/Library/LibraryComment.vue')
          }
        ]
      },

      {
        path: 'profile',
        name: 'Profile',
        component: () => store.getters.istablet?import('@/views/TabView/ProfileTablet.vue'):import('@/views/TabView/Profile.vue'),
        children: [
          {
            path: '/profile/help',
            name: 'ProfileHelp',
            component: () => import('@/views/Popup/Profile/Help.vue'),
            meta:{
              keepAlive:true
            },
          },
          {
            path: '/profile/help/article/:articleid',
            name: 'ProfileHelpArticle',
            component: () => import('@/views/Popup/Resource/Article.vue')
          },
          {
            path: '/profile/notify',
            name: 'ProfileNotify',
            component: () => import('@/views/Popup/Profile/Help.vue')
          },
          {
            path: '/profile/about',
            name: 'ProfileAbout',
            component: () => import('@/views/Popup/Profile/About.vue')
          },
          {
            path: '/profile/info',
            name: 'ProfileInfo',
            component: () => import('@/views/Popup/Profile/Info.vue')
          },
          {
            path: '/profile/share',
            name: 'ProfileShare',
            component: () => import('@/views/Popup/Profile/ShareOthers.vue')
          },
          {
            path: '/profile/info/account',
            name: 'ProfileInfoAccount',
            component: () => import('@/views/Popup/Profile/InfoAccount.vue')
          },
          {
            path: '/profile/info/changeusername/:username',
            name: 'ProfileInfoChangeusername',
            component: () => import('@/views/Popup/Profile/ChangeUsername.vue')
          },
          {
            path: '/profile/info/vip/:id',
            name: 'ProfileInfoVip',
            component: () => import('@/views/Popup/Profile/InfoAccountVip.vue')
          },
          {
            path: '/profile/info/upgrade/:groupid/:targetid',
            name: 'ProfileInfoUpgrade',
            component: () => import('@/views/Popup/Profile/InfoAccountUp.vue')
          },
          {
            path: '/profile/info/recharge/:count',
            name: 'ProfileInfoRecharge',
            component: () => import('@/views/Popup/Profile/InfoAccountRecharge.vue')
          },
          {
            path: '/profile/info/account/modifypwd/:contactnum',
            name: 'ProfileInfoAccountPwd',
            component: () => import('@/views/Popup/Profile/InfoAccountPwd.vue')
          },
          {
            path: '/profile/info/account/logoutaccount/:contactnum',
            name: 'ProfileInfoAccountLogout',
            component: () => import('@/views/Popup/Profile/InfoAccountLogout.vue')
          },
          {
            path: '/profile/info/account/:contact',
            name: 'ProfileInfoAccountEdit',
            component: () => import('@/views/Popup/Profile/InfoAccountEdit.vue')
          },
          {
            path: '/profile/about/history',
            name: 'ProfileAboutHistory',
            component: () => import('@/views/Popup/Profile/AboutHistory.vue')
          },
          {
            path: '/profile/about/communities',
            name: 'ProfileAboutCommunities',
            component: () => import('@/views/Popup/Profile/AboutCommunities.vue')
          },
          {
            path: '/profile/about/contactus',
            name: 'ProfileAboutContactus',
            component: () => import('@/views/Popup/Profile/AboutContactus.vue')
          },
          {
            path: '/profile/about/article/:articleid',
            name: 'ProfileAboutArticle',
            component: () => import('@/views/Popup/Resource/Article.vue')
          },
          {
            path: '/profile/:profileitem/:name',
            name: 'ProfileDetail',
            component: () => import('@/views/Popup/Profile/Shopping.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/login/:articleid?',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () =>
      import(/* webpackChunkName: "signup" */ '@/views/Signup.vue')
  },
  {
    path: '/bind',
    name: 'Bind',
    component: () => import('@/views/Bind.vue')
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/Forgot.vue')
  },
  {
    path: '/rules/:articleid',
    name: 'Rules',
    component: () => import('@/views/Rules.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '@/views/Test.vue')
  },
  {
    path: '/waysdownload',
    name: 'Downloadway',
    component: () => import('@/views/Downloadway.vue')
  }
]

const forEachRoutes = (
  routes: Array<RouteRecordRaw>,
  fn: (route: RouteRecordRaw, depth: number) => void,
  depth = 0
) => {
  routes.forEach((route) => {
    fn(route, depth)
    if (Array.isArray(route.children) && route.children.length) {
      forEachRoutes(route.children, fn, depth + 1)
    }
  })
}

forEachRoutes(routes, (route, depth) => {
  if (route.meta) {
    route.meta.depth = depth
  } else {
    route.meta = {
      depth
    }
  }
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
